import { ADMIN_API } from '../utils';

export const generateUrlSignOnline = async (formData) => {
  try {
    const res = await ADMIN_API.post('/vivid/generate-link', formData);
    return res.data;
  } catch (error) {
    throw error;
  }
};
