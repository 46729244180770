import React, {useEffect, useMemo, useState} from 'react';
import {Form, Input, Divider, Row, Col, InputNumber, Checkbox, Modal} from 'antd';
import _ from 'lodash';
import dayjs from 'dayjs';
import {FILTER_MASTER, CONTRACT_TYPE} from '../constants';
import {getImageObject, getUrlObject} from '../../fn';
import {convertNumberToComma, convertStringToNumber} from '../../../../functions/fn';
import {Icon} from '../../../../resources/icon';
import {ButtonTheme, Spinners, Upload} from '../../../../components';
import {SerialNumberInput, InputCoupon, DatatableCouponDetail} from '../components';
import {SelectMDMProvider, SelectMDMSite, SelectPeriods, SelectTypeLock} from '../../../../components/inputfromapi';
import {GCS_BUCKET_CONTRACT_IMAGE, GCS_PATH_CONTRACT_IMAGE} from '../../../../env';
import {POST, RAMDOM_MAIL, GET_FGF_DISCOUNT} from '../../../../services';
import {getAdminMDMDefault} from '../../../../apiv2';
import {calculateInstallment} from '../../../../services/api/svapi/product';

/*
coupon: [
  {
    coupon_id: 'PON-25-0006',
    coupon_code: 'REGISTERCOUPON',
    coupon_discount: 200,
    coupon_description: 'REGISTERCOUPON',
  },
];
*/

const formName = 'FormTurn2New';
const {TURN2} = CONTRACT_TYPE;
const INIT_DISCOUNT_PERIOD = {
  label: '', //'ส่วนลดค่าเครื่อง:1000'
  code: '', //DISPERIOD1000
  discount: '', // 1000
};
const ININT_CAL = {
  contractType: TURN2, //ประเภทสัญญา
  down_amount: 0, //เงินดาวน์
  price: 0, //ราคา
  periods: 0, //งวด
  productCode: [], //รหัสสินค้า
  coupon: [], //คูปอง
  discountPeriod: {...INIT_DISCOUNT_PERIOD}, //ส่วนลดค่าเครื่อง
  fgfCommission: 0, // commission friend get friend
};

const FormTurn2New = ({defaultData, data, fn, setActiveTab, newState, setNewState, turnState = {formData: {}, state: {}}}) => {
  const {record, contractType} = data;
  const {checkCustomer, setErrorMessage} = fn;

  /* Form */
  const [form] = Form.useForm();
  /* Product */
  const [productList, setProductList] = useState(newState?.state?.productList || []);
  const [serialnumber, setSerialnumber] = useState(newState?.state?.serialnumber || []);
  /* Coupon */
  const [couponList, setCouponList] = useState(newState?.state?.couponList || []); // ['REGISTERCOUPON', 'REGISTERCOUPON1']
  const [couponDataList, setCouponDataList] = useState(newState?.state?.couponDataList || []); // coupon only
  const [couponDetailList, setCouponDetailList] = useState(newState?.state?.couponDetailList || []); // รายละเอียดคูปอง coupon + discountPeriod
  /* Type Lock */
  const [typeLock, setTypeLock] = useState(newState?.state?.typeLock || '');
  const [defaultMDMByKey, setDefaultMDMByKey] = useState(newState?.state?.defaultMDMByKey || '');
  /* Frind Get Friend */
  const [fgfData, setFGFData] = useState(newState?.state?.fgfData || {});
  const [fgfTextExtra, setFGFTextExtra] = useState(newState?.state?.fgfTextExtra || '');
  /* Calculate */
  const [calculateData, setCalculateData] = useState(newState?.state?.calculateData || {...ININT_CAL});
  /* File */
  const [fileImageList, setFileImageList] = useState(newState?.state?.fileImageList || []);
  /* CheckMail */
  const [checkMail, setCheckMail] = useState(newState?.state?.checkMail || false);
  /* Loading API */
  const [loadingUpload, setLoadingUpload] = useState(false);
  const [calculateLoading, setCalculateLoading] = useState(false);
  const [getAdminMDMDefaultLoading, setMDMDefaultLoading] = useState(false);

  /* --------- useMemo calculateData ---------*/
  const calDtMemo = useMemo(() => {
    return calculateData;
  }, [calculateData]);

  /* --------- useEffect get default MDM ---------*/
  useEffect(() => {
    if (_.isEmpty(newState?.state?.defaultMDMByKey)) {
      getAdminMDMDefaultFn();
    }

    if (_.isEmpty(newState?.state?.fgfData)) {
      getFGFCodeFn(record?.id_card);
    }
  }, [record?.id_card]);

  /* --------- useEffect set default data ---------*/
  useEffect(() => {
    if ((!_.isEmpty(defaultData) && contractType === defaultData?.type) || !_.isEmpty(newState)) {
      setDefaultData();
    }
  }, [defaultData]);

  /* --------- useEffect calculateFn ---------*/
  useEffect(() => {
    if (contractType && calDtMemo?.down_amount && calDtMemo?.periods && calDtMemo?.price) {
      calculateFn(calDtMemo);
    }
  }, [
    contractType,
    calDtMemo?.down_amount,
    calDtMemo?.price,
    calDtMemo?.periods,
    calDtMemo?.discountPeriod?.discount,
    calDtMemo?.coupon,
    fgfData?.commission_amount,
  ]);

  /* --------- useEffect set all (discountPeriod+coupon) ---------*/
  useEffect(() => {
    handleSetCouponList();
  }, [calDtMemo?.discountPeriod?.code, calDtMemo?.coupon]);

  /* --------- useEffect set Calculate coupon only ---------*/
  useEffect(() => {
    setCalculateData((prev) => ({...prev, coupon: couponDataList}));
  }, [couponDataList]);

  /* --------- API Fn ---------*/
  const getAdminMDMDefaultFn = async () => {
    try {
      setMDMDefaultLoading(true);
      const res = await getAdminMDMDefault();

      if (res) {
        const MDMDefaultKey = _.keyBy(res, 'type_lock');
        setDefaultMDMByKey(MDMDefaultKey);
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถดึงค่า default MDM ได้',
        content: err?.message,
      });
    } finally {
      setMDMDefaultLoading(false);
    }
  };

  const getFGFCodeFn = async (idCard) => {
    try {
      const res = await POST(GET_FGF_DISCOUNT, {id_card: idCard});
      const {friend_get_friend, success} = res;
      if (success) {
        form.setFieldValue('friend_get_friend', friend_get_friend?.code);
        setFGFData(friend_get_friend);
        setFGFTextExtra(`ส่วนลด ${convertNumberToComma(friend_get_friend?.commission_amount)} บาท` || '');
      } else {
        setFGFTextExtra(res?.message || 'ไม่พบข้อมูล');
      }
    } catch (err) {
      Modal.error({title: 'ไม่สามารถดึงค่า friend get friend ได้', content: err?.message});
    }
  };

  const calculateFn = async (params) => {
    try {
      const {down_amount, periods, price, discountPeriod, coupon, fgfCommission} = params;
      if (!_.isEmpty(params)) {
        setCalculateLoading(true);
        const inputParmas = {
          type: contractType,
          price: price || 0,
          priceTurn: turnState?.formData?.priceTurn || 0,
          down: down_amount,
          discountPeriod: Number(discountPeriod?.discount) || 0,
          period: periods,
          couponDiscount: (_.sumBy(coupon, 'coupon_discount') || 0) + (fgfCommission || 0),
        };

        const response = await calculateInstallment(inputParmas);

        const {success, result} = response;
        if (success) {
          const sumDiscount = result?.discount?.couponDiscount + result?.discount?.discountPeriod;

          const setFieldsValue = {
            discount: sumDiscount || 0,
            installments: result?.installments || 0,
            total: result?.total || 0,
            change_amount: result?.changeAmount,
          };
          form.setFieldsValue(setFieldsValue);
        }
      }
    } catch (err) {
      Modal.error({
        title: 'ไม่สามารถคํานวณราคาได้',
        content: err?.message,
      });
    } finally {
      setCalculateLoading(false);
    }
  };

  const getMailIcloudOld = async () => {
    const obj = {
      useOldMail: true,
      idCard: record?.id_card,
    };
    try {
      const {success, result} = await POST(RAMDOM_MAIL, obj);
      if (success) {
        setCheckMail(true);
        form.setFieldValue('checkMail', true);
        form.setFieldValue('mail', result.mail);
      }
    } catch (err) {
      const {message} = err;
      setCheckMail(false);
      setErrorMessage({form, error: true, name: 'checkMail', txtError: [message]});
    }
  };

  /* --------- Fn ---------*/
  const setDefaultData = async () => {
    const {summary} = defaultData;

    form.setFieldsValue({
      down_amount: summary?.downPayment || 0,
      periods: summary?.period,
      ...newState?.formData,
    });

    setCalculateData((prev) => ({
      ...prev,
      down_amount: summary?.downPayment || 0,
      periods: summary?.period,
      ...newState?.state?.calculateData,
    }));
  };

  const handleChangeSerialNumber = async (value) => {
    const productList = value?.productList || value;

    if (!_.isEmpty(productList)) {
      const serialNumber = _.map(productList, 'serialnumber');
      const productCode = _.map(productList, 'code');
      const periods = calDtMemo?.periods ? calDtMemo?.periods : productList[0]?.periodOptions[0]?.value;
      form.setFields([{name: 'serial_number', value: serialNumber, errors: []}]);

      setSerialnumber(serialNumber);
      setProductList(productList);
      setCalculateData((prev) => ({...prev, productCode: productCode}));

      handlePeriodsChange(periods, productList);
    } else {
      form.resetFields(['coupon_code', 'discount', 'installments', 'total', 'price', 'sale_grade', 'periods', 'serial_number']);

      setSerialnumber([]);
      setProductList([]);
      setCalculateData((prev) => ({
        ...prev,
        price: 0,
        discountPeriod: {
          label: '',
          code: '',
          discount: '',
        },
        coupon: [],
        productCode: '',
      }));
      setCouponList([]);
      setCouponDataList([]);
      setCouponDetailList([]);
    }
  };

  const handleDownAmountChange = (value) => {
    form.setFields([{name: 'down_amount', value: value}]);
    setCalculateData((prev) => ({...prev, down_amount: value}));
  };

  const calPrice = (price, combo) => {
    return (Number(price) || 0) + (Number(combo?.price1) || 0);
  };

  const handlePeriodsChange = (periods, productDt) => {
    const product = productDt ?? productList;
    const price = calPrice(product[0]?.productSaleByPeriod[periods]?.price, product[1]?.productCombo);
    const saleGrade = product[0]?.saleGrade;

    const discountPeriod =
      product?.length === 0
        ? product[0]?.productSaleByPeriod[periods]?.discountPeriod || {...INIT_DISCOUNT_PERIOD}
        : product[product?.length - 1]?.productSaleByPeriod[periods]?.discountPeriod || {...INIT_DISCOUNT_PERIOD};

    form.setFields([
      {name: 'periods', value: periods, errors: []},
      {name: 'price', value: price, errors: []},
      {name: 'sale_grade', value: saleGrade, errors: []},
    ]);
    setCalculateData((prev) => ({...prev, periods: periods, price: price, discountPeriod: discountPeriod}));
  };

  const handleCouponChange = (value) => {
    form.setFields([{name: 'coupon_code', VALUE: value, errors: []}]);
  };

  const handleSetCouponList = () => {
    const {discountPeriod, coupon = []} = calDtMemo;

    const couponCode = _.map(coupon, 'coupon_code');
    const couponCodeAll = _.compact([discountPeriod?.code, ...couponCode]);

    const couponDetail = [
      ...(discountPeriod?.code
        ? [
            {
              coupon_code: discountPeriod.code,
              coupon_description: discountPeriod.label,
              coupon_discount: discountPeriod.discount,
            },
          ]
        : []),
      ...(coupon ?? []),
    ];

    setCouponList(couponCodeAll);
    setCouponDetailList(couponDetail);
  };

  const handleChangeTypeLock = (value) => {
    setTypeLock(value);
    form.setFields([
      {name: 'type_lock', value: value, errors: []},
      {name: 'mdm_provider_code', value: defaultMDMByKey[value]?.mdm_provider_code || '', errors: []},
      {name: 'mdm_site_code', value: defaultMDMByKey[value]?.mdm_site_code || '', errors: []},
    ]);
  };

  const handleChangeCheckMail = (e) => {
    // clear Error of field 'checkMail' before fire api
    setErrorMessage({form, error: false, name: 'checkMail', txtError: [], value: e.target.checked});

    if (e?.target?.checked) {
      setCheckMail(true);
      getMailIcloudOld();
    } else {
      form.setFieldValue('mail', '');
      setCheckMail(false);
    }
  };

  const handleClearForm = () => {
    form.resetFields();
    setProductList([]);
    setSerialnumber([]);
    setCouponList([]);
    setCouponDataList([]);
    setCouponDetailList([]);
    setTypeLock('');
    setCalculateData({...ININT_CAL});
    setFileImageList([]);
    setCheckMail(false);
  };

  const onClickBack = () => {
    const formData = form.getFieldsValue();
    setNewState({
      formData: formData,
      state: {
        productList,
        serialnumber,
        couponList,
        couponDataList,
        couponDetailList,
        typeLock,
        defaultMDMByKey,
        fgfData,
        fgfTextExtra,
        calculateData,
        fileImageList,
        checkMail,
      },
    });
    setActiveTab('PRODUCT_TURN');
  };

  const onFinish = async (values) => {
    try {
      const {
        down_amount,
        friend_get_friend,
        periods,
        type_lock,
        mdm_provider_code,
        mdm_site_code,
        checkMail,
        mail,
        image,
        remark,
        isOldContract,
        change_amount,
      } = values;

      /** Product Turn **/
      const {
        product_code,
        serial_number,
        condition,
        branch,
        priceTurn,
        machineDetailImage,
        imeiImage,
        otherImage,
        imei,
        insurance_status,
        insurance_date,
      } = turnState.formData;
      const productTurnCode = product_code;
      const productTurnName = turnState?.state?.productTurnData?.productName || '';

      const serialNumberList = [
        {
          product_code: productTurnCode,
          product_name: productTurnName,
          serial_number: serial_number,
          price: priceTurn,
          imei: imei,
          insurance: {
            date: insurance_status === 'YES' ? dayjs(insurance_date).format('YYYY-MM-DD') : null,
            status: insurance_status,
          },
        },
      ];

      /** Product **/
      const productCodeList = _.map(productList, (el, idx) => {
        return {
          code: el?.code,
          name: el?.name,
          serialNumber: el?.serialnumber,
          price: idx === 0 ? el?.productSaleByPeriod[periods]?.price || 0 : el?.productCombo?.price1 || 0,
        };
      });

      const couponCode = _.map(couponDataList, (el) => {
        return {code: el?.coupon_code};
      });

      const otherImageList = _.map(otherImage, (el) => {
        return getUrlObject('otherImage', el?.url);
      });

      const payload = {
        user_id: record?._id,
        type: contractType,
        branch: branch,
        serial_number: serialNumberList, //Product Turn
        productCode: productCodeList,
        contract: {
          type: contractType,
          down_amount: down_amount,
          periods: periods,
          type_lock: type_lock === 'NONE' ? '' : type_lock,
          mdm_provider_code: mdm_provider_code,
          mdm_site_code: mdm_site_code,
          checkMail: checkMail,
          image: {url: image},
          package: '',
          price_package: 0,
          promotion_code: 'none',
          couponCode: couponCode,
          ...(checkMail ? {mail} : {}),
          ...(image ? getImageObject('image', image) : {}),
          ...(machineDetailImage ? getImageObject('machineDetailImage', machineDetailImage) : {}),
          ...(imeiImage ? getImageObject('imeiImage', imeiImage) : {}),
          otherImage: otherImageList,
          discount_period: calDtMemo?.discountPeriod?.code ? calDtMemo?.discountPeriod : {},
        },
        ...(friend_get_friend ? {friend_get_friend: fgfData} : {}),
        coupon_id: couponDataList[0]?.coupon_id ?? '',
        remark: remark,
        isOldContract: isOldContract,
        change_amount: change_amount,
        condition: condition,
        productTurn: [
          {
            code: productTurnCode,
            name: productTurnName,
            serialNumber: serial_number,
            price: priceTurn,
          },
        ],
      };
      await checkCustomer(payload);
    } catch (error) {}
  };

  return (
    <>
      {(getAdminMDMDefaultLoading || calculateLoading) && <Spinners />}
      <Form
        form={form}
        name={formName}
        layout={'horizontal'}
        labelAlign="right"
        labelCol={{span: 4}}
        wrapperCol={{span: 20}}
        style={{padding: '0px 20px 20px 20px'}}
        onFinish={onFinish}
        autoComplete="off"
        scrollToFirstError>
        <Form.Item name={'serial_number'} label="บาร์โค้ดสินค้า" rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <SerialNumberInput
            setErrorMessage={setErrorMessage}
            onChange={(value) => handleChangeSerialNumber(value)}
            onDelete={(value) => handleChangeSerialNumber(value)}
            type={contractType}
            product={productList}
            form={form}
          />
        </Form.Item>

        <Divider />

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'sale_grade'} label="Sale Grade" labelCol={{span: 8}}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'price'} label="ราคาขาย" labelCol={{span: 8}}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'down_amount'} label="เงินดาวน์" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <InputNumber
                style={{width: '100%'}}
                formatter={(value, info) => {
                  if (!info.userTyping) {
                    return convertNumberToComma(value);
                  } else {
                    return value;
                  }
                }}
                onBlur={(e) => handleDownAmountChange(convertStringToNumber(e?.target?.value))}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'friend_get_friend'} label="รหัสเพื่อนชวนเพื่อน" labelCol={{span: 8}} extra={fgfTextExtra}>
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'coupon_code'} label={'Code คูปอง'} labelCol={{span: 8}} extra={'เมื่อกรอกโค้ดเสร็จ กรุณากด "Enter'}>
              <InputCoupon
                form={form}
                fieldName={'coupon_code'}
                setErrorMessage={setErrorMessage}
                disabledCoupon={[calDtMemo?.discountPeriod?.code]}
                couponList={couponList}
                setCouponList={setCouponList}
                couponDataList={couponDataList}
                setCouponDataList={setCouponDataList}
                productCodeList={_.map(productList, (el) => el?.code)}
                onChange={(e) => handleCouponChange(e)}
                disabled={_.isEmpty(productList)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'discount'} label="ส่วนลด" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={2}></Col>
          <Col span={22}>
            <DatatableCouponDetail data={couponDetailList} />
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'periods'} label="จำนวนงวด" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectPeriods
                placeholder={'เลือกจำนวนงวด'}
                handleChange={(e) => handlePeriodsChange(e?.value)}
                filter={{type: contractType, serialNumber: serialnumber, isTurn: false}}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name={'installments'} label="ยอดผ่อนต่อเดือน" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'total'} label="ยอดชำระรวม" labelCol={{span: 8}} initialValue={0}>
              <InputNumber style={{width: '100%'}} disabled formatter={convertNumberToComma} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item name={'type_lock'} label="การล็อคเครื่อง" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
              <SelectTypeLock placeholder={'เลือกรูปแบบการล็อกเครื่อง'} handleChange={(e) => handleChangeTypeLock(e?.value)} filter={FILTER_MASTER} />
            </Form.Item>
          </Col>
        </Row>

        {typeLock && ['MDMICLOUD', 'MDM'].includes(typeLock) && (
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name={'mdm_provider_code'}
                label="ผู้ให้บริการ MDM"
                labelCol={{span: 8}}
                rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMProvider
                  placeholder={'เลือกผู้ให้บริการ MDM'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_provider_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'mdm_site_code'} label="MDM Site" labelCol={{span: 8}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
                <SelectMDMSite
                  placeholder={'เลือก MDM Site'}
                  handleChange={(e) => {
                    form.setFields([{name: 'mdm_site_code', value: e?.value, errors: []}]);
                  }}
                  filter={FILTER_MASTER}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <Row gutter={[16, 16]}>
          {typeLock && ['MDMICLOUD', 'ICLOUD'].includes(typeLock) && (
            <Col span={12}>
              <Form.Item
                name={'checkMail'}
                label="อีเมล์ ไอคลาวน์"
                labelCol={{span: 8}}
                initialValue={false}
                valuePropName="checked"
                onChange={handleChangeCheckMail}>
                <Checkbox style={{marginLeft: 5}}>ใช้บัญชีเดิม</Checkbox>
              </Form.Item>
            </Col>
          )}
          {typeLock && ['MDMICLOUD', 'ICLOUD'].includes(typeLock) && checkMail && (
            <Col span={12}>
              <Form.Item name={'mail'} label="อีเมล์" labelCol={{span: 8}}>
                <Input disabled />
              </Form.Item>
            </Col>
          )}
        </Row>

        <Form.Item name={'image'} label="รูปผู้ทำสัญญา" labelCol={{span: 4}} rules={[{required: true, message: 'จำเป็นต้องกรอก'}]}>
          <Upload
            accept=".png,.jpg,.jpeg"
            subDir={`${GCS_PATH_CONTRACT_IMAGE}`}
            bucket={`${GCS_BUCKET_CONTRACT_IMAGE}`}
            defaultFileList={fileImageList}
            handleChange={(files) => {
              form.setFields([{name: 'image', value: files[0]?.url, errors: []}]);
              setFileImageList([{fileName: files[0]?.fileName, url: files[0]?.url}]);
            }}
            setLoading={setLoadingUpload}
            handleRemove={() => {
              form.setFields([{name: 'image', value: null, errors: []}]);
            }}
          />
        </Form.Item>

        <Form.Item name={'remark'} label="หมายเหตุ" labelCol={{span: 4}}>
          <Input />
        </Form.Item>

        <Form.Item name={'isOldContract'} label="สร้างสัญญาแบบเก่า" labelCol={{span: 4}} initialValue={false} valuePropName="checked">
          <Checkbox style={{marginLeft: 5}} />
        </Form.Item>

        <Form.Item name={'change_amount'} label="เงินทอน/เงินขาด" labelCol={{span: 4}}>
          <InputNumber style={{width: '100%'}} formatter={convertNumberToComma} disabled />
        </Form.Item>

        <Row justify={'center'}>
          <ButtonTheme
            useFor="CLEAR"
            style={{width: 150}}
            onClick={() =>
              Modal.confirm({
                title: 'ยืนยัน',
                icon: <Icon.warningAlert />,
                content: 'ต้องการล้างข้อมูลทั้งหมดใช่หรือไม่?',
                onOk() {
                  handleClearForm();
                },
                okText: 'ยืนยัน',
                cancelText: 'ยกเลิก',
              })
            }
          />
          <ButtonTheme useFor="BACK" onClick={() => onClickBack()} title={'ย้อนกลับ'} style={{width: 150}} loading={loadingUpload} />
          <ButtonTheme useFor="CUSTOM" htmlType="submit" title={'ยืนยัน'} style={{width: 150}} loading={loadingUpload} />
        </Row>
      </Form>
    </>
  );
};

export default FormTurn2New;
